/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Get in touch"), "\n", React.createElement(_components.p, null, "You can view my Resume if you click ", React.createElement(_components.a, {
    href: "url"
  }, "THIS"), ". You can always send me a mail on diprajghosh01@gmail.com Find me on any one of these platforms: ", React.createElement(_components.a, {
    href: "https://www.linkedin.com/in/dipraj-ghosh-a13056159/"
  }, "LinkedIn"), "  | ", React.createElement(_components.a, {
    href: "https://www.facebook.com/diprajriki/"
  }, "Facebook"), " | ", React.createElement(_components.a, {
    href: "https://www.instagram.com/dipraj_ghosh/"
  }, "Instagram")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
