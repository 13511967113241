/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "About"), "\n", React.createElement(_components.p, null, "I’m a Electrical and Electronics Engineering graduate and DevOps aficionado. I have significant experience in devOps particularly using Jenkins,Docker,Ansible. I’ve worked on projects where I built the CI/CD pipeline, Automation from scratch. I have also spent considerable time as an undergraduate honing my problem solving skills working on AWS Cloud. I love my work because I get to create something out of nothing. Please let me know if I can add value to your company or brand. I’d love to discuss my projects, previous work and educational training in greater detail so you can make sure I’m capable of delivering the high standards of quality you expect. Cheers :)"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
