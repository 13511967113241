/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components), {ProjectCard} = _components;
  if (!ProjectCard) _missingMdxReference("ProjectCard", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Projects"), "\n", React.createElement(ProjectCard, {
    title: "Agoi Financial Services",
    link: "http://www.agoifinancialservices.com/",
    bg: "linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
  }, React.createElement(_components.p, null, "Deployed the website through CI/CD pipeline.\r\nTools used - Jenkins, Docker & Ansible.")), "\n", React.createElement(ProjectCard, {
    title: "Setting  up a yarn Workspace",
    link: "https://www.lekoarts.de/javascript/setting-up-a-yarn-workspace-with-typescript-eslint-and-cypress?utm_source=cara&utm_medium=Theme",
    bg: "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
  }, React.createElement(_components.p, null, "A robust solution for multi-package repositories.")), "\n", React.createElement(ProjectCard, {
    title: "Adding a Draft Feature to Gatsby",
    link: "https://www.lekoarts.de/garden/adding-a-draft-feature-to-gatsby?utm_source=cara&utm_medium=Theme",
    bg: "linear-gradient(to right, #009245 0%, #FCEE21 100%)"
  }, React.createElement(_components.p, null, "A solution with the createSchemaCustomization API.")), "\n", React.createElement(ProjectCard, {
    title: "Theme UI Plugin for Figma",
    link: "https://www.lekoarts.de/design/introducing-the-theme-ui-plugin-for-figma?utm_source=cara&utm_medium=Theme",
    bg: "linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "The Theme UI plugin for Figma allows for a workflow where Theme UI is the\r\nstarting point both for design & development.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
